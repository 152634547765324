import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

const Table = (props) => {
  return (
    <>
      <table className={`table ${props.classname}`}>
        <thead>
          <tr>
            <th>#</th>
            <th>Booking ID</th>
            <th>Owner</th>
            <th>Walker</th>
            <th>Rate for owner</th>
            <th>Rate for walker</th>
            <th>Registration date</th>
            <th>Booking date </th>
            <th>Review for walker</th>
            <th>Review for owner</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {props.bookings &&
            props.bookings.length > 0 &&
            props.bookings.map((a, index) =>
              (
                <tr key={a._id}>
                  <td>{index + 1}</td>
                  <td>{'Gowalk' + a._id.substring(a._id.length - 4, a._id.length)}</td>
                  <td>{a.ownerId ? a.ownerId.basicInfo.fullName : '-'} </td>
                  <td>{a.walkerId ? a.walkerId.basicInfo.fullName : '-'} </td>
                  <td>{a.ratingByWalker ? a.ratingByWalker.rating : '-'}</td>
                  <td>{a.ratingByOwner ? a.ratingByOwner.rating : '-'}</td>
                  <td>{a.created_at ? moment(a.created_at).format('MMM DD, YYYY') : '-'}</td>

                  <td>{a.date || '-'}</td>

                  <td
                    onClick={() =>
                      props.handleReview(
                        a.ratingByWalker && a.ratingByWalker.review || '-',
                        'Review For Walker', a._id, 'owner', a?.ratingByWalker?.isRatingApproved || ''
                      )}
                  >
                    {
                      a?.ratingByWalker?.isRatingApproved  || a?.ratingByWalker?.isRatingApproved === undefined?
                      <i className='fa fa-eye '/>
                      :
                      <i class="fa fa-eye-slash" aria-hidden="true" />
                    }
                  
                  </td>
                  <td
                    onClick={() =>
                      props.handleReview(
                        a.ratingByOwner && a.ratingByOwner.review || '-',
                        'Review For Owner', a._id, 'walker', a?.ratingByOwner?.isRatingApproved || ''
                      )}
                  >
                     {
                      a?.ratingByOwner?.isRatingApproved  || a?.ratingByOwner?.isRatingApproved === undefined?
                      <i className='fa fa-eye '/>
                      :
                     <i class="fa fa-eye-slash" aria-hidden="true" />
                    }
                  </td>
                  <td>
                    <span className={a.status}>{a.status}</span>
                  </td>
                  <td>
                    <Link to='#' onClick={() => props.showBookingDetails(a)}>
                      <i className='fa fa-eye' />
                    </Link>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </>
  )
}

export default Table
