import React from 'react'
import moment from 'moment'
import { api_url } from '../../../utils/api'

const BasicDetails = (props) => {
  const { data } = props
  console.log(data)

  const image =
    data.basicInfo && data.basicInfo.image ? data.basicInfo.image : api_url + '/default.png'

  return (
    <>
      <img src={image && image} alt='profile-img' className='details-image' />

      <span
        className={
          data.basicInfo && data.basicInfo.isUserBlocked ? 'Blocked' : 'Not Blocked'
        }
      />
      {/* <h6 className="mt-3"># {data ? data._id : ""}</h6> */}
      <h1>{data.basicInfo && data.basicInfo.fullName}</h1>
      <h6>
        {data.basicInfo && data.basicInfo.email}{' '}
        <sup
          className={
            data.basicInfo
              ? data.basicInfo.isVerified
                ? 'verified'
                : 'unverified'
              : ''
          }
        >
          {data.basicInfo
            ? data.basicInfo.isVerified
              ? 'verified'
              : 'unverified'
            : ''}
        </sup>
      </h6>

      <h6>{data.experience ? data.experience + ' experience' : ''}</h6>
      <p>{moment(data && data.created_at).format('MMM DD, YYYY')}</p>
    </>
  )
}

export default BasicDetails
