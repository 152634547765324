import React from 'react'
import { MenuItem } from 'react-pro-sidebar'
import { NavLink } from 'react-router-dom'
import Icon from '../Icon/Icon'

const SubMenuItem = ({ name, link, handleClick, icon, collapsed }) => {
  return (
    <>
      <MenuItem>
        <NavLink to={link} onClick={handleClick}>
          <Icon icon={icon} />
          {collapsed ? '' : name}
        </NavLink>
      </MenuItem>
    </>
  )
}

export default SubMenuItem
