import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import Table from './Table'
import Pagination from '../../common/Pagination/Pagination'
import Search from '../../common/Search/Search'
import {
  getOwner,
  unblockOwner,
  createOwner,
  deleteOwner,
  getAllDeletedUser,
  searchOwners
} from '../../../actions/dashboardActions'
import './styles.scss'

class DeletedUser extends Component {
  constructor() {
    super()
    this.state = {
      id: '',
      query: '',
      pageLimit: 10,
      currentPage: 1
    }
  }

  componentDidMount() {
    const token = localStorage.getItem('jwtToken')
    const data = sessionStorage.getItem('deleteUserCurrentPage') || this.state.currentPage
    // Get the data from sessionStorage
    this.setState({ currentPage: parseInt(data) })
    this.props.getAllDeletedUser(token, data, this.state.pageLimit)
  }


  handleSearch = (query) => {
    const token = localStorage.getItem('jwtToken')

    this.setState({ query })
    this.props.searchOwners(query, token)
  }

  handlePageClick = (page) => {
    const token = localStorage.getItem('jwtToken')
    this.setState({ currentPage: page })
    this.props.getAllDeletedUser(token, page, this.state.pageLimit)
  }


  render() {
    const currentPageLength = this.props.dashboard.allDeletedUser.length
    const totalDeletedUser = sessionStorage.getItem('total_deleted_user') || 0
    const totalNoOfDeletedUser = sessionStorage.getItem('total_no_deleted_user') || 0
    const currentRecordsFrom =
      this.state.pageLimit * (this.state.currentPage - 1) + 1
    const currentRecordsTo = currentRecordsFrom + currentPageLength - 1
    return (
      <>
        <div className='manage-patient-wrapper'>
          <h5 class='text-left'>Deleted User</h5>
          <div className='row mx-0'>
            <div className='col-md-6 pl-0 search_col'>
              <Search
                icon='fa fa-search icon'
                size='15px'
                placeholder='Search Owner'
                search={this.state.query}
                handleSearch={this.handleSearch}
              />
            </div>
            <div className='col-md-6 pr-0 right_col' />
          </div>
          <div className='manage-patient'>
            <div class='table-responsive'>
              {' '}
              <Table
                deletedUser={this.props.dashboard.allDeletedUser}
                accessRole={this.props.accessRole}
                classname='dashboard_table'
              />
            </div>
          </div>

          <div className='row result_row mx-0'>
            <div className='col-md-6 px-0 left_col'>
              {!this.state.query && (
                <h6 className='results'>
                  Showing {currentRecordsFrom} - {currentRecordsTo} results of{' '}
                  {totalNoOfDeletedUser}
                </h6>
              )}
            </div>
            <div className='col-md-6 px-0 right_col'>
              {!this.state.query && (
                <Pagination
                  count={totalDeletedUser}
                  totalItem={totalNoOfDeletedUser}
                  pageLimit={this.state.pageLimit}
                  currentPage={this.state.currentPage}
                  handlePageClick={this.handlePageClick}
                />
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

DeletedUser.propTypes = {
  dashboard: PropTypes.object.isRequired,
  unblockOwner: PropTypes.func.isRequired,
  createOwner: PropTypes.func.isRequired,
  deleteOwner: PropTypes.func.isRequired,
  getAllDeletedUser: PropTypes.func.isRequired,
  searchOwners: PropTypes.func.isRequired,
  getOwner: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  accessRole: state.dashboard.accessRole,

})

export default connect(mapStateToProps, {
  unblockOwner,
  createOwner,
  getOwner,
  deleteOwner,
  getAllDeletedUser,
  searchOwners
})(DeletedUser)
