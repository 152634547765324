import React from 'react'

const Icon = ({ icon, size, float }) => {
  return (
    <div className='icon' style={{ fontSize: size, float }}>
      <img src={icon} />
    </div>
  )
}

export default Icon
