import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ToastContainer, Slide } from 'react-toastify'
import { Provider } from 'react-redux'
import store from './store'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/scss/bootstrap.scss'
import './App.scss'
import { RouteWrapper } from './components/startup/RouteWrapper'
import Login from './components/auth/Login/Login'
import ForgotPassword from './components/auth/ForgotPassword/ForgotPassword'
import ResetPassword from './components/auth/ResetPassword/ResetPassword'
import Admin from './components/layouts/Admin'
import ManageWalker from './components/panel/Walker/ManageWalker'
import WalkerDetails from './components/panel/Walker/WalkerDetails'
import Bookings from './components/panel/Bookings/Bookings'
import UpdateAbout from './components/panel/CMS/About'
import Profile from './components/panel/Profile/Profile'
import DocFile from './components/panel/Bookings/DocFile'
import ManageCommission from './components/panel/Commission/ManageCommission'
import AddFAQ from './components/panel/FAQ/AddFAQ'
import ManageFAQ from './components/panel/FAQ/ManageFAQ'
import ManageCategory from './components/panel/Category/ManageCategory'
import ChangePassword from './components/panel/Settings/ChangePassword'
import Notification from './components/panel/Settings/Notification'
import VerifyEmail from './components/panel/VerifyEmail/VerifyEmail'
import PrivacyPolicy from './components/panel/Policy/Policy'
import About from './components/panel/CMS/About'
import Policy from './components/panel/CMS/Policy'
import Terms from './components/panel/CMS/Terms-and-condition'
import WalkerTerms from './components/panel/CMS/WalkerTerms'
import TermsCondition from './components/panel/Terms/Terms'
import WalkerTermsCondition from './components/panel/Terms/WalkerTerms'
import AboutUs from './components/panel/About/About'
import ManageOwner from './components/panel/Owner/ManageOwner'
import OwnerDetails from './components/panel/Owner/OwnerDetails'
import Success from './components/panel/Success/Success'
import OnboardingSuccess from './components/panel/Success/onBoardingSuccess'
import DeletedUser from './components/panel/DeletedUser/DeletedUser'
import DeletedUserDetail from './components/panel/DeletedUser/DeletedUserDetail'

function App() {
  return (
    <Provider store={store}>
      <Router>
        <>
          <ToastContainer
            position='top-right'
            autoClose={2000}
            hideProgressBar
            newestOnTop
            closeOnClick
            transition={Slide}
          />
          <Switch>
            {/* <Route exact path="/terms/and/conditions" component={ShowTerms} /> */}
            <Route exact path='/verify/email/:id' component={VerifyEmail} />
            <Route exact path='/admin/login' component={Login} />
            <Route
              exact
              path='/admin/forgot/password'
              component={ForgotPassword}
            />
            <Route
              exact
              path='/admin/reset/password'
              component={ResetPassword}
            />
            <Route
              exact
              path='/admin/appointment/doc/:doc_file'
              component={DocFile}
            />
            <Route exact path='/privacy-policy' component={PrivacyPolicy} />
            <Route
              exact
              path='/terms-and-condition'
              component={TermsCondition}
            />
            <Route
              exact
              path='/walker/terms-and-condition'
              component={WalkerTermsCondition}
            />
            <Route exact path='/about' component={AboutUs} />
            <Route exact path='/success' component={Success} />
            <Route exact path='/onboarding/success' component={OnboardingSuccess} />

            <RouteWrapper
              exact
              path='/'
              component={ManageWalker}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path='/admin/walkers'
              component={ManageWalker}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path='/admin/walkers'
              component={ManageWalker}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path='/admin/walker-details/:id'
              component={WalkerDetails}
              layout={Admin}
            />

            <RouteWrapper
              exact
              path='/admin/bookings'
              component={Bookings}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path='/admin/faq/add'
              component={AddFAQ}
              layout={Admin}
            />

            <RouteWrapper
              exact
              path='/admin/faq/manage'
              component={ManageFAQ}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path='/admin/about'
              component={UpdateAbout}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path='/admin/about'
              component={About}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path='/admin/privacy-policy'
              component={Policy}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path='/admin/terms-and-condition'
              component={Terms}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path='/admin/walker/terms-and-condition'
              component={WalkerTerms}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path='/admin/profile'
              component={Profile}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path='/admin/commission'
              component={ManageCommission}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path='/admin/deleted/user'
              component={DeletedUser}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path='/admin/deleted/user-details/:id/:accountType'
              component={DeletedUserDetail}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path='/admin/password'
              component={ChangePassword}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path='/admin/notification'
              component={Notification}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path='/admin/categories'
              component={ManageCategory}
              layout={Admin}
            />

            <RouteWrapper
              exact
              path='/admin/owners'
              component={ManageOwner}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path='/admin/owner-details/:id'
              component={OwnerDetails}
              layout={Admin}
            />
          </Switch>
        </>
      </Router>
    </Provider>
  )
}

export default App
