import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import LazyLoadImage from '../../common/Lazy/Image'
import ExportDataAsCSV from '../../common/Modal/ExportDataAsCSV'
import { api_url } from '../../../utils/api'

const Table = (props) => {
  const [data, setData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [walkers, setWalkers] = useState(props.walkers)

  const { accessRole } = props
  const history = useHistory()
  const handleModalClose = () => {
    setShowModal(false)
  }

  const handleNoBtnClick = () => {
    setShowModal(false)
  }

  const handleYesBtnClick = () => {
    setShowModal(false)
  }
  useEffect(()=>{
    setWalkers(props.walkers)
  },[props.walkers])

  return (
    <>
      <table className={`table ${props.classname}`}>
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Name</th>
            <th>Email</th>
            <th>Profile status</th>
            <th>Average Rating</th>
            <th>Date Registered</th>
            <th>Hourly Rate</th>
            <th>Active</th>
            <th>Approval</th>
            <th>Insurance</th>
            <th className='mw-210'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {walkers &&
            walkers.length > 0 ?
            walkers.map((el, index) => (
              <tr key={el._id}>
                <td>{index + 1}</td>
                <td>
                  <LazyLoadImage
                    src={el.basicInfo.image || api_url + '/default.png'}
                    alt='doctor-image'
                    width='50'
                  />
                </td>
                <td>{el.basicInfo.fullName ? el.basicInfo.fullName : '-'}</td>
                <td>{el.basicInfo.email}</td>
                <td>
                  <span className={`${el.basicInfo.image ? 'complete' : 'incomplete'}`}>
                    {el.basicInfo.image ? 'Complete' : 'Incomplete'}
                  </span>
                </td>
                <td>{el.rating || '-'}</td>
                <td>{moment(el.created_at).format('MMM DD, YYYY')}</td>
                <td>{el.fee || '-'}</td>
                <td>
                  <span
                    className={
                      el.basicInfo.isUserBlocked ? 'suspended' : 'active'
                    }
                  >
                    {el.basicInfo.isUserBlocked ? 'suspended' : 'active'}
                  </span>
                </td>
                {accessRole === '0' ?
                  <td className='approve'>
                    <span
                      className={el.isApproved ? 'suspended' : 'active'}
                      onClick={() => props.handleApproveUser(el._id)}
                    >
                      {el.isApproved ? 'Reject' : 'Approve'}
                    </span>
                  </td>
                  :
                  <td >
                    <span
                      className={el.isApproved ? 'suspended' : 'active'}
                    >
                      {el.isApproved ? 'Reject' : 'Approve'}
                    </span>
                  </td>
                }
                {accessRole === '0' ?
                <td className='mw-120 approve'>
                  <span
                    className={el.isEnsure ? 'suspended' : 'active'}
                    onClick={() => props.handleEnsureUser(el._id)}
                  >
                    {el.isEnsure ? 'Not insure' : 'Insured'}
                  </span>
                </td>:
                 <td className='mw-120'>
                 <span
                   className={el.isEnsure ? 'suspended' : 'active'}
                 >
                   {el.isEnsure ? 'Not insure' : 'Insured'}
                 </span>
               </td>}

                
                <td className='mw-170'>
                  <div onClick={() => history.push(`/admin/walker-details/${el._id}`, { el })} className='btn'>
                    <i className='fa fa-eye' />
                  </div>
                  {accessRole === '0' ?
                    <>
                      <Link to='#' className='btn'>
                        <i
                          className= {el.basicInfo.isUserBlocked ? 'fa fa-lock' : 'fa fa-unlock'}
                          onClick={() => props.handleUnblockUser(el._id)}
                        />
                      </Link>
                      <Link to='#' className='btn'>
                        <i
                          className='fa fa-trash'
                          onClick={() => props.deleteModalClicked(el._id)}
                        />
                      </Link>
                    </> : null}
                </td>
              </tr>
            )):null}
        </tbody>
      </table>

      <ExportDataAsCSV
        showModal={showModal}
        handleModalClose={handleModalClose}
        title='Export Data as CSV'
        text='Are you sure you want to export data in csv format?'
        data={data}
        handleNoBtnClick={handleNoBtnClick}
        handleYesBtnClick={handleYesBtnClick}
      />
    </>
  )
}

export default Table
