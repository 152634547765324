import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { toast } from 'react-toastify'
import {
  IS_LOADING,
  ADMIN_PROFILE_DATA,
  FAQ,
  ABOUT,
  HELP,
  TERM,
  SINGLE_FAQ,
  ALL_REVIEWS,
  VERIFY_ERROR,
  VERIFY_SUCCESS,
  GET_QUESTION,
  ALL_CATEGORY,
  GET_WALKERS,
  GET_WALKER,
  GET_OWNERS,
  GET_OWNER,
  GET_CMS,
  GET_BOOKINGS,
  GET_COMMISSION,
  GET_USER_DETAIL,
  GET_DELETE_USER
} from './types'
import { api_url } from '../utils/api'

/* ------------------------------------------------------------------------------------- */

// Verify Email
export const verifyEmail = (id) => (dispatch) => {
  axios
    .post(api_url + '/api/admin/verify/email', { id })
    .then((res) => {
      if (res.data.error) {
        dispatch({ type: VERIFY_ERROR, payload: res.data.error })
      }

      if (res.data.success) {
        dispatch({ type: VERIFY_SUCCESS, payload: res.data.msg })
      }
    })
    .catch((err) => { return err })
}

/* ------------------------------------------------------------------------------------- */
export const createOwner = (owner, token) => (dispatch) => {
  axios
    .post(api_url + '/api/admin/owner/add', owner, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error)

      if (res.data.success) {
        dispatch({ type: GET_OWNERS, payload: res.data.data.owners })
        sessionStorage.setItem('total_owners', res.data.data.owners.length)
        toast.success(res.data.msg)
      }
    })
    .catch((err) => console.log(err))
}
/* ------------------------------------------------------------------------------------- */

export const createWalker = (walker, token) => (dispatch) => {
  console.log('walker====', walker)
  axios
    .post(api_url + '/api/admin/walker/add', walker, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.error) {
        toast.error(res.data.error)
      }
      if (res.data.success) {
        sessionStorage.setItem('total_walkers', res.data.data.walkers.length)
        dispatch({ type: GET_WALKERS, payload: res.data.data.walkers })
        toast.success(res.data.msg)
      }
    })
    .catch((err) => console.log(err))
}

/* ------------------------------------------------------------------------------------- */

/* category ------------------------------------------------------------------------------------- */

export const addCategory = (category, token) => (dispatch) => {
  dispatch({ type: IS_LOADING, payload: true })
  console.log('categoru', category)
  axios
    .post(api_url + '/api/admin/category/add', category, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.error) {
        dispatch({ type: IS_LOADING, payload: false })
        return toast.error(res.data.error)
      }
      if (res.data.success) {
        dispatch({
          type: ALL_CATEGORY,
          payload: res.data.data.categories
        })

        dispatch({ type: IS_LOADING, payload: false })

        toast.success(res.data.msg)
      }
    })
    .catch((err) => console.log(err))
}
/* ------------------------------------------------------------------------------------- */

// Profile

export const getProfile = (token) => (dispatch) => {
  try {
    var decode = jwt_decode(token)
  } catch (error) {
    console.log(error)
  }

  if (decode) {
    console.log('decode==', decode)
    axios
      .get(api_url + `/api/admin/profile/${decode.id}`, {
        headers: { 'x-access-token': token }
      })
      .then((res) => {
        if (res.data.success) {
          sessionStorage.setItem('walkerCurrentPage', '1')
          sessionStorage.setItem('bookingCurrentPage', '1')
          sessionStorage.setItem('ownerCurrentPage', '1')
          dispatch({ type: ADMIN_PROFILE_DATA, payload: res.data.data.admin })
        }
      })
      .catch((err) => console.log(err))
  }
}

export const submitProfile = (profile, token) => (dispatch) => {
  try {
    dispatch({ type: IS_LOADING, payload: true })

    var decode = jwt_decode(token)
  } catch (error) {
    console.log(error)
  }

  profile.append('id', decode.id)

  axios
    .post(api_url + '/api/admin/profile', profile, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({ type: IS_LOADING, payload: false })
        toast.success('Profile updated')
        // console.log("return admin data", req.data.data.admin)
        dispatch({ type: ADMIN_PROFILE_DATA, payload: res.data.data.admin })
      }
    })
    .catch((err) => console.log(err))
}

/* ------------------------------------------------------------------------------------- */

export const getBookings = (
  token,
  limit,
  page
) => (dispatch) => {
  console.log('calls get booking')
  axios
    .get(
      api_url +
      `/api/admin/bookings/get/${page}/${limit}`,
      {
        headers: { 'x-access-token': token }
      }
    )
    .then((res) => {
      if (res.data.error) {
        return toast.error('No Booking(s) found')
      }

      if (res.data.success) {
        // if (res.data.data.Bookings.length > 0) {
        sessionStorage.setItem('bookingCurrentPage', page.toString())
        sessionStorage.setItem('walkerCurrentPage', '1')
        sessionStorage.setItem('ownerCurrentPage', '1')
        sessionStorage.setItem('total_no_bookings', res.data.data.totalBookings)
        sessionStorage.setItem(
          'total_bookings',
          res.data.data.Bookings.length
        )
        dispatch({
          type: GET_BOOKINGS,
          payload: res.data.data.Bookings
        })
        // }
      }
    })
    .catch((err) => console.log(err))
}

/* ------------------------------------------------------------------------------------- */

// Static Content

export const postFAQ = (text, token) => (dispatch) => {
  dispatch({ type: IS_LOADING, payload: true })

  axios
    .post(api_url + '/api/admin/faq/post', text, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({ type: IS_LOADING, payload: false })
        toast.success(res.data.msg)
      }
    })
    .catch((err) => console.log(err))
}

export const updateFAQ = (faq, token) => (dispatch) => {
  axios
    .put(api_url + '/api/admin/faq/update', faq, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        //  dispatch({ type: IS_LOADING, payload: false });
        toast.success(res.data.msg)
      }
    })
    .catch((err) => console.log(err))
}

export const postSubFAQ = (text, token) => (dispatch) => {
  dispatch({ type: IS_LOADING, payload: true })

  axios
    .post(api_url + '/api/admin/faq/subfaq/post', text, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({ type: IS_LOADING, payload: false })
        toast.success(res.data.msg)
      }
    })
    .catch((err) => console.log(err))
}

export const allFAQ = (token, page, limit) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/faq/all/${page}/${limit}`, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: FAQ,
          payload: res.data.data.faq
        })
      }

      sessionStorage.setItem('total_faq', res.data.data.all_faq)
    })
    .catch((err) => console.log(err))
}

export const getFAQ = (id, token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/faq/get/${id}`, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: SINGLE_FAQ,
          payload: res.data.data.faq
        })
      }
    })
    .catch((err) => console.log(err))
}

export const deleteFAQ = (id, token) => (dispatch) => {
  axios
    .post(
      api_url + '/api/admin/faq/delete',
      { id },
      {
        headers: { 'x-access-token': token }
      }
    )
    .then((res) => {
      if (res.data.success) {
        toast.success(res.data.msg)
      }
    })
    .catch((err) => console.log(err))
}

export const searchFAQ = (query, token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/faq/search?query=${query}`, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: FAQ,
          payload: res.data.data.faq
        })
      }
    })
    .catch((err) => console.log(err))
}

export const postAbout = (text, token) => (dispatch) => {
  axios
    .post(
      api_url + '/api/admin/about/post',
      { text },
      {
        headers: { 'x-access-token': token }
      }
    )
    .then((res) => {
      if (res.data.success) {
        toast.success(res.data.msg)
        dispatch({
          type: ABOUT,
          payload: res.data.data.about
        })
      }
    })
    .catch((err) => console.log(err))
}

export const getAbout = (token) => (dispatch) => {
  axios
    .get(api_url + '/api/admin/about/get', {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: ABOUT,
          payload: res.data.data.about
        })
      }
    })
    .catch((err) => console.log(err))
}

export const postHelp = (text, token) => (dispatch) => {
  axios
    .post(
      api_url + '/api/admin/help/post',
      { text },
      {
        headers: { 'x-access-token': token }
      }
    )
    .then((res) => {
      if (res.data.success) {
        toast.success(res.data.msg)
        dispatch({
          type: HELP,
          payload: res.data.data.help
        })
      }
    })
    .catch((err) => console.log(err))
}

export const getHelp = (token) => (dispatch) => {
  axios
    .get(api_url + '/api/admin/help/get', {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: HELP,
          payload: res.data.data.help
        })
      }
    })
    .catch((err) => console.log(err))
}

export const postTerm = (text, token) => (dispatch) => {
  axios
    .post(
      api_url + '/api/admin/term/conditions/post',
      { text },
      {
        headers: { 'x-access-token': token }
      }
    )
    .then((res) => {
      if (res.data.success) {
        toast.success(res.data.msg)
        dispatch({
          type: TERM,
          payload: res.data.data.term
        })
      }
    })
    .catch((err) => console.log(err))
}

export const getTerm = (token) => (dispatch) => {
  axios
    .get(api_url + '/api/admin/term/conditions/get', {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: TERM,
          payload: res.data.data.term
        })
      }
    })
    .catch((err) => console.log(err))
}

/* ------------------------------------------------------------------------------------- */
// Commission

export const getCommission = (token) => (dispatch) => {
  axios
    .get(`${api_url}/api/admin/commission/get`, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        sessionStorage.setItem('walkerCurrentPage', '1')
        sessionStorage.setItem('bookingCurrentPage', '1')
        sessionStorage.setItem('ownerCurrentPage', '1')
        dispatch({
          type: GET_COMMISSION,
          payload: res.data.data.commission
        })
      }
    })
    .catch((err) => console.log(err))
}

export const updateCommission = (commission, token) => (dispatch) => {
  axios
    .post(`${api_url}/api/admin/commission/update`, { commission }, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_COMMISSION,
          payload: res.data.data.commission
        })

        toast.success(res.data.msg)
      }
    })
    .catch((err) => console.log(err))
}

/* ------------------------------------------------------------------------------------- */

// Settings

export const changePassword = (oldPass, newPass, token, history) => (
  dispatch
) => {
  axios
    .post(
      api_url + '/api/admin/change/password',
      { currentPassword: oldPass, newPassword: newPass },
      {
        headers: { 'x-access-token': token }
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error)

      if (res.data.success) {
        localStorage.removeItem('jwtToken')
        toast.success(res.data.msg)
        history.push('/admin/login')
      }
    })
    .catch((err) => console.log(err))
}

/* ------------------------------------------------------------------------------------- */

// Send Notification

export const sendNotification = (token, data) => (
  dispatch
) => {
  axios
    .post(
      api_url + '/api/admin/send/notification',
      data,
      {
        headers: { 'x-access-token': token }
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error)
    })
    .catch((err) => console.log(err))
}

// Ratings & Reviews

export const allReviews = (token, limit, page) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/reviews/get/${limit}/${page}`, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({ type: ALL_REVIEWS, payload: res.data.data.reviews })

        sessionStorage.setItem('total_reviews', res.data.data.all_reviews)
      }
    })
    .catch((err) => console.log(err))
}

export const deleteReview = (id, token) => (dispatch) => {
  axios
    .post(
      api_url + '/api/admin/reviews/delete',
      { id },
      {
        headers: { 'x-access-token': token }
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error)

      if (res.data.success) toast.success(res.data.msg)
    })
    .catch((err) => console.log(err))
}

export const searchReview = (query, token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/reviews/search?query=${query}`, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error)

      if (res.data.success) {
        dispatch({ type: ALL_REVIEWS, payload: res.data.data.reviews })
      }
    })
    .catch((err) => console.log(err))
}

export const updateReview = (review, token) => (dispatch) => {
  axios
    .post(api_url + '/api/admin/reviews/update', review, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error)

      if (res.data.success) toast.success(res.data.msg)
    })
    .catch((err) => console.log(err))
}

/* ------------------------------------------------------------------------------------- */
export const getAllWalkers = (token, page, limit,callback) => (
  dispatch
) => {
  const pageNumber = page
  const pageLimit = limit

  axios
    .get(
      api_url +
      `/api/walker/get/walkers/${pageLimit}/${pageNumber}`,
      {
        headers: { 'x-access-token': token }
      }
    )
    .then((res) => {
      if (res.data.success) {
        sessionStorage.setItem('ownerCurrentPage', '1')
        sessionStorage.setItem('bookingCurrentPage', '1')
        sessionStorage.setItem('total_walkers', res.data.data.walkers.length)
        sessionStorage.setItem('total_no_walkers', res.data.data.totalWalkers)
        sessionStorage.setItem('walkerCurrentPage', page.toString())
        sessionStorage.setItem('allWalkers', JSON.stringify(res.data.data.walkers))
        dispatch({ type: GET_WALKERS, payload: res.data.data.walkers })
      }
    })
    .catch((err) => console.log(err))
}

export const getWalker = (walkerId, token) => (dispatch) => {
  axios
    .post(
      api_url +
      '/api/walker/get/walkerById', { walkerId },
      {
        headers: { 'x-access-token': token }
      }
    )
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_WALKER,
          walkerInfo: res.data.data.user,
          walkerBookings: res.data.data.bookings,
          walkerReviews: res.data.data.reviews
        })
      }
    })
    .catch((err) => console.log(err))
}

export const unblockWalker = (walker_id, token,currentPage,pageLimit) => (
  dispatch
) => {
  axios
    .get(
      api_url +
      `/api/walker/unblock/${walker_id}/${currentPage}/${pageLimit}`,
      {
        headers: { 'x-access-token': token }
      }
    )
    .then((res) => {
      if (res.data.success) {
        toast.success(res.data.msg)
        sessionStorage.setItem('ownerCurrentPage', '1')
        sessionStorage.setItem('bookingCurrentPage', '1')
        sessionStorage.setItem('total_walkers', res.data.data.walkers.length)
        sessionStorage.setItem('total_no_walkers', res.data.data.totalWalkers)
        sessionStorage.setItem('walkerCurrentPage', currentPage.toString())
        sessionStorage.setItem('allWalkers', JSON.stringify(res.data.data.walkers))
        dispatch({ type: GET_WALKERS, payload: res.data.data.walkers })
      }
    })
    .catch((err) => console.log(err))
}

export const blockWalker = (walker_id, token) => (dispatch) => {
  axios
    .get(
      api_url +
      `/api/walker/block?id=${walker_id}`,
      {
        headers: { 'x-access-token': token }
      }
    )
    .then((res) => {
      if (res.data.success) {
        toast.success(res.data.msg)
      }
    })
    .catch((err) => console.log(err))
}

export const searchWalkers = (query, token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/walker/search?query=${query}`, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_WALKERS,
          payload: res.data.data.walkers
        })
      }
    })
    .catch((err) => console.log(err))
}

export const updateWalker = (walker, token) => (dispatch) => {
  axios
    .put(api_url + '/api/admin/walker/update', walker, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.error) return toast.success(res.data.error)

      if (res.data.success) return toast.success(res.data.msg)
    })
    .catch((err) => console.log(err))
}

export const deleteWalker = (id, token) => (dispatch) => {
  axios
    .post(
      api_url + '/api/admin/walker/delete',
      { id },
      {
        headers: { 'x-access-token': token }
      }
    )
    .then((res) => {
      if (res.data.error) {
        return toast.error(res.data.error)
      }
      if (res.data.success) {
        return toast.success(res.data.msg)
      }
    })
    .catch((err) => console.log(err))
}

/* ------------------------------------------------------------------------------------- */

// Owners

export const getAllOwners = (token, page, limit) => (dispatch) => {
  const pageNumber = page
  const pageLimit = limit

  axios
    .get(api_url + `/api/owner/get/owners/${pageLimit}/${pageNumber}`, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        sessionStorage.setItem('ownerCurrentPage', page.toString())
        sessionStorage.setItem('total_no_owners', res.data.data.totalOwners)
        sessionStorage.setItem('walkerCurrentPage', '1')
        sessionStorage.setItem('bookingCurrentPage', '1')
        dispatch({ type: GET_OWNERS, payload: res.data.data.owners })
        sessionStorage.setItem('total_owners', res.data.data.owners.length)
      }
    })
    .catch((err) => console.log(err))
}

// Owners

export const getAllDeletedUser = (token, page, limit) => (dispatch) => {
  const pageNumber = page
  const pageLimit = limit

  axios
    .get(api_url + `/api/admin/get/deleted/user/${pageLimit}/${pageNumber}`, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        sessionStorage.setItem('deleteUserCurrentPage', page.toString())
        sessionStorage.setItem('total_no_deleted_user', res.data.data.deletedUser.length)
        sessionStorage.setItem('ownerCurrentPage', '1')
        sessionStorage.setItem('walkerCurrentPage', '1')
        sessionStorage.setItem('bookingCurrentPage', '1')
        dispatch({ type: GET_DELETE_USER, payload: res.data.data.deletedUser })
        sessionStorage.setItem('total_deleted_user', res.data.data.deletedUser.length)
      }
    })
    .catch((err) => console.log(err))
}

export const getOwner = (ownerId, token) => (dispatch) => {
  axios
    .post(api_url + '/api/owner/get/ownerById', { ownerId }, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_OWNER,
          ownerInfo: res.data.data.user,
          ownerBookings: res.data.data.bookings,
          reviews: res.data.data.reviews
        })
      }
    })
    .catch((err) => console.log(err))
}
export const getUserDetail = (id, token) => (dispatch) => {
  axios
    .post(api_url + '/api/admin/get/user/detail', { id }, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_USER_DETAIL,
          userInfo: res.data.data.user,
          userBookings: res.data.data.bookings,
        })
      }
    })
    .catch((err) => console.log(err))
}
export const unblockOwner = (owner_id, token) => (dispatch) => {
  axios
    .get(api_url + `/api/owner/unblock/${owner_id}`, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        toast.success(res.data.msg)
      }
    })
    .catch((err) => console.log(err))
}

export const blockOwner = (owner_id, token) => (dispatch) => {
  axios
    .get(api_url + `/api/owner/block/${owner_id}`, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        toast.success(res.data.msg)
      }
    })
    .catch((err) => console.log(err))
}

export const searchOwners = (query, token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/owner/search?query=${query}`, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_OWNERS,
          payload: res.data.data.owners
        })
      }
    })
    .catch((err) => console.log(err))
}

export const updateOwner = (owner, token) => (dispatch) => {
  axios
    .post(api_url + '/api/admin/owner/update', owner, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        return toast.success(res.data.msg)
      }
    })
    .catch((err) => console.log(err))
}

export const deleteOwner = (id, token) => (dispatch) => {
  axios
    .post(
      api_url + '/api/admin/owner/delete',
      { id },
      {
        headers: { 'x-access-token': token }
      }
    )
    .then((res) => {
      if (res.data.error) {
        return toast.error(res.data.error)
      }
      if (res.data.success) {
        return toast.success(res.data.msg)
      }
    })
    .catch((err) => console.log(err))
}

export const approveWalker = (walkerId, token,currentPage,pageLimit) => (dispatch) => {
  axios
  .get(api_url + `/api/admin/approve/${walkerId}/${currentPage}/${pageLimit}`, {
    headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        toast.success(res.data.msg)
        sessionStorage.setItem('ownerCurrentPage', '1')
        sessionStorage.setItem('bookingCurrentPage', '1')
        sessionStorage.setItem('total_walkers', res.data.data.walkers.length)
        sessionStorage.setItem('total_no_walkers', res.data.data.totalWalkers)
        sessionStorage.setItem('walkerCurrentPage', currentPage.toString())
        sessionStorage.setItem('allWalkers', JSON.stringify(res.data.data.walkers))
        dispatch({ type: GET_WALKERS, payload: res.data.data.walkers })
      }
    })
    .catch((err) => console.log(err))
}
export const ensureWalker = (walkerId, token , currentPage,pageLimit) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/ensure/${walkerId}/${currentPage}/${pageLimit}`, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        toast.success(res.data.msg)
        sessionStorage.setItem('ownerCurrentPage', '1')
        sessionStorage.setItem('bookingCurrentPage', '1')
        sessionStorage.setItem('total_walkers', res.data.data.walkers.length)
        sessionStorage.setItem('total_no_walkers', res.data.data.totalWalkers)
        sessionStorage.setItem('walkerCurrentPage', currentPage.toString())
        sessionStorage.setItem('allWalkers', JSON.stringify(res.data.data.walkers))
        dispatch({ type: GET_WALKERS, payload: res.data.data.walkers })
      }
    })
    .catch((err) => console.log(err))
}

export const addAbout = (text, token) => (dispatch) => {
  axios
    .post(
      api_url + '/api/admin/cms/about/update',
      { about: text },
      {
        headers: { 'x-access-token': token }
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error)

      if (res.data.success) {
        toast.success(res.data.msg)
      }
    })
    .catch((err) => console.log(err))
}
export const addPolicy = (text, token, language) => (dispatch) => {
  axios
    .post(
      api_url + '/api/admin/cms/policy/update',
      { policy: text, language },
      {
        headers: { 'x-access-token': token }
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error)

      if (res.data.success) {
        toast.success(res.data.msg)
      }
    })
    .catch((err) => console.log(err))
}

export const addTerms = (text, type, token, language) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/cms/${type === 'walker' ? 'walker/terms/update' : 'terms/update'}`,
      { terms: text, language },
      {
        headers: { 'x-access-token': token }
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error)

      if (res.data.success) {
        toast.success(res.data.msg)
      }
    })
    .catch((err) => console.log(err))
}
export const getCMS = (token) => (dispatch) => {
  axios
    .get(api_url + '/api/admin/cms/get', {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error)

      if (res.data.success) {
        sessionStorage.setItem('walkerCurrentPage', '1')
        sessionStorage.setItem('bookingCurrentPage', '1')
        sessionStorage.setItem('ownerCurrentPage', '1')
        dispatch({ type: GET_CMS, payload: res.data.data.cms })
      }
    })
    .catch((err) => console.log(err))
}

export const searchBooking = (query, token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/booking/search?query=${query}`, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_BOOKINGS,
          payload: res.data.data.bookings
        })
      }
    })
    .catch((err) => console.log(err))
}

export const filterBookings = (
  token,
  limit,
  page,
  fromDate,
  toDate
) => (dispatch) => {
  const payload = { limit, page, fromDate, toDate }
  axios
    .post(
      api_url +
      '/api/admin/booking/filter', { payload },
      {
        headers: { 'x-access-token': token }
      }
    )
    .then((res) => {
      if (res.data.error) {
        return toast.error('No Booking(s) found')
      }

      if (res.data.success) {
        dispatch({
          type: GET_BOOKINGS,
          payload: res.data.data.Bookings
        })
      }
    })
    .catch((err) => console.log(err))
}

export const approveRating = (id, type, token,callback) => (dispatch) => {
  axios
    .post(api_url + '/api/admin/approve/rating', { id, type }, {
      headers: { 'x-access-token': token }
    })
    .then((res) => {
      if (res.data.success) {
        toast.success(res.data.msg)
        return callback(true)
      }
    })
    .catch((err) => console.log(err))
}
