import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import LazyLoadImage from '../../common/Lazy/Image'
import ExportDataAsCSV from '../../common/Modal/ExportDataAsCSV.js'
import { api_url } from '../../../utils/api'

const Table = (props) => {
  const [data, setData] = useState([])
  const [showModal, setShowModal] = useState(false)

  const handleModalClose = () => {
    setShowModal(false)
  }

  const handleNoBtnClick = () => {
    setShowModal(false)
  }

  const handleYesBtnClick = () => {
    setShowModal(false)
  }

  const saveDataAsCSV = (owner) => {
    const csvData = [
      [
        'ID',
        'Image',
        'Full Name',
        'Email',
        'Phone Number'
      ],
      [
        owner._id,
        owner.basicInfo.image,
        owner.basicInfo.fullName,
        owner.basicInfo.email,
        owner.basicInfo.phoneNumber
      ]
    ]

    setData(csvData)
    setShowModal(true)
  }

  const adminData = props.adminProfileData

  return (
    <>
      <table className={`table ${props.classname}`}>
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Name</th>
            <th>Email</th>
            <th>Average Rating</th>
            <th>Date Registered</th>
            <th>Status</th>
            <th className='mw-210'>Action</th>
          </tr>
        </thead>
        <tbody>
          {props.owners &&
            props.owners.length > 0 &&
            props.owners.map((p, index) => (
              <tr key={p._id}>
                <td>{index + 1}</td>
                <td>
                  <LazyLoadImage
                    src={
                      p.basicInfo && p.basicInfo.image
                        ? p.basicInfo.image
                        : api_url + '/default.png'
                    }
                    alt='profile'
                  />
                </td>

                <td>{p.basicInfo.fullName || '-'}</td>
                <td>{p.basicInfo.email || '-'}</td>
                <td>{p.rating || '-'}</td>
                <td>{moment(p.created_at).format('MMM DD, YYYY')}</td>
                <td>
                  <span
                    className={
                      p.basicInfo.isUserBlocked ? 'suspended' : 'active'
                    }
                  >
                    {p.basicInfo.isUserBlocked ? 'Suspended' : 'Active'}
                  </span>
                </td>
                <td>
                  <Link
                    to={`/admin/owner-details/${p._id}`}
                    className='btn edit'
                  >
                    <i className='fa fa-eye' />
                  </Link>
                  {props.accessRole === '0' ?
                  <>
                      <Link to='#' className='btn unlock'>
                    <i
                      className= {p.basicInfo.isUserBlocked ? 'fa fa-lock' : 'fa fa-unlock'}
                      onClick={() => props.unblockUser(p._id)}
                    />
                  </Link>
                  <Link to='#' className='btn delete'>
                      <i
                        className='fa fa-trash'
                        onClick={() => props.deleteModalClicked(p._id)}
                      />
                    </Link>
                  </>:null}
                  {/*
                  <Link to="#">
                    <i
                      className="fa fa-trash"
                      onClick={() => props.deleteUser(p._id)}
                    ></i>
                  </Link> */}

                  {/* <Link to="#" onClick={() => saveDataAsCSV(p)}>
                    <i className="fa fa-save"></i>
                  </Link> */}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <ExportDataAsCSV
        showModal={showModal}
        handleModalClose={handleModalClose}
        title='Export Data as CSV'
        text='Are you sure you want to export data in csv format?'
        data={data}
        handleNoBtnClick={handleNoBtnClick}
        handleYesBtnClick={handleYesBtnClick}
      />
    </>
  )
}

export default Table
