import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../Button/Button'
import FormInput from '../Form-Input/FormInput'
import './styles.scss'

const AddWalker = (props) => {
  return (
    <>
      <Modal
        show={props.showAddWalkerModal}
        onHide={props.handleAddWalkerModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add Walker
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={props.handleAddWalkerSubmit}>

            <FormInput
              icon='fa fa-user icon'
              size='15px'
              type='text'
              name='fullName'
              placeholder='Full Name'
              value={props.fullName}
              handleChange={props.handleChange}
            />

            <FormInput
              icon='fa fa-envelope icon'
              size='13px'
              type='email'
              name='email'
              placeholder='Email'
              className='form-control shadow-none'
              value={props.email}
              handleChange={props.handleChange}
            />

            <FormInput
              icon='fa fa-phone icon'
              type='text'
              name='phoneNumber'
              placeholder='Phone Number'
              className='form-control shadow-none'
              handleChange={props.handleChange}
            />
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddWalker
