import React, { memo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import Form from './Form'
import BasicDetails from './BasicDetails'
import BookingHistory from './BookingHistory'
import { getOwner, updateOwner } from '../../../actions/dashboardActions'

const OwnerDetails = (props) => {
  const { id } = useParams()
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  useEffect(() => {
    const token = localStorage.getItem('jwtToken')
    props.getOwner(id, token)
  }, [])
  useEffect(() => {
    if (props.dashboard.singleOwnerInfo.basicInfo) {
      setFullName(props.dashboard.singleOwnerInfo.basicInfo.fullName)
      setEmail(props.dashboard.singleOwnerInfo.basicInfo.email)
      setPhoneNumber(props.dashboard.singleOwnerInfo.basicInfo.phoneNumber)
    }
  }, [props])

  const handleDetailsUpdate = (e) => {
    e.preventDefault()

    const token = localStorage.getItem('jwtToken')

    const owner = {
      id,
      fullName,
      email,
      phoneNumber
    }

    props.updateOwner(owner, token)
    props.getOwner(id, token)
  }

  return (
    <>
      <div className='patient-details'>
        <h5 className='text-left'>Owner Details</h5>
        <div className='row mx-0'>
          <div className='col-md-4 pl-0'>
            <div className='basic-details'>
              <BasicDetails
                details={
                  props.dashboard.singleOwnerInfo
                    ? props.dashboard.singleOwnerInfo
                    : ''
                }
              />
            </div>
          </div>
          <div className='col-md-8 pr-0'>
            <div className='personal-details'>
              <h5 className='text-left'>Personal Details</h5>
              <Form
                fullName={fullName}
                email={email}
                setEmail={setEmail}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                handleDetailsUpdate={handleDetailsUpdate}
                accessRole={props.accessRole}
              />
            </div>
          </div>
        </div>

        <div className='row mx-0'>
          <div className='col-md-12 px-0'>
            <div className='appointment-history'>
              <h5 className='text-left'>Booking History</h5>
              <div className='table-responsive'>
                {' '}
                <BookingHistory
                  data={props.dashboard.ownerBookings}
                  ownerInfo={props.dashboard.ownerInfo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

OwnerDetails.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getOwner: PropTypes.func.isRequired,
  updateOwner: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  accessRole: state.dashboard.accessRole

})

export default connect(mapStateToProps, { getOwner, updateOwner })(
  memo(OwnerDetails)
)
