import React, { memo } from 'react'
import './styles.scss'

const Search = ({ icon, size, placeholder, search, handleSearch, styles }) => {
  return (
    <>
      <div className='form-group input-icons search'>
        <i className={icon} style={{ fontSize: size }} />
        <input
          type='text'
          id='search'
          placeholder={placeholder}
          className='form-control shadow-none'
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
          style={styles}
        />
      </div>
    </>
  )
}

export default memo(Search)
