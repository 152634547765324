import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getProfile } from '../../../actions/dashboardActions'
import { logoutUser } from '../../../actions/authActions'
import { api_url } from '../../../utils/api'
import './styles.scss'
import left_arrow from '../../../images/left-arrow.png'

const Navbar = (props) => {
  const [authPopup, setAuthPopup] = useState(null)
  const [notificationPopup, setNotificationPopup] = useState(null)
  const token = localStorage.getItem('jwtToken')

  useEffect(() => {
    props.getProfile(token)
  }, [])

  const handleLogout = () => {
    props.logoutUser(token, props.history)
  }

  let image = api_url + '/default.png'

  if (props.dashboard.adminProfileData) {
    if (props.dashboard.adminProfileData.image) {
      image = `${props.dashboard.adminProfileData.image}`
    }
  }

  const name = props.dashboard.adminProfileData
    ? props.dashboard.adminProfileData.firstName
    : ''

  const auth_dropdown_handler = () => {
    if (!authPopup) {
      setAuthPopup('show')
    } else {
      setAuthPopup(null)
    }
  }

  const currentLocation = props.location.pathname

  return (
    <>
      <nav className='navbar sticky-top'>

        <button
          className={`back ${props.location.pathname === '/' ? 'disabled' : ''
            }`}
          onClick={props.history.goBack}
          disabled={currentLocation === '/'}
        >
          <img src={left_arrow} />
        </button>
        <ul className='navbar-nav'>
          <li className='nav-item notification'>
            <div className='dropdown'>

              <div
                className={`dropdown-menu dropdown-menu-notifications ${notificationPopup ? 'show' : null
                  }`}
                aria-labelledby='notification-bell'
              >
                <p>
                  Notifications{' '}
                  <span>
                    <Link to='#'>Mark all as read</Link>
                  </span>
                </p>
                <div className='dropdown-divider' />
                <Link to='#' className='dropdown-item px-0 py-2'>
                  <div className='notification_list'>
                    <span class='image'>
                      <img
                        src='/user_default.png'
                        id='notification-img'
                        alt='img'
                      />
                    </span>
                    <span class='message'>
                      <p className='notification-text'>Test Message</p>
                      <p className='notification-time'>Oct 02, 2020</p>
                    </span>
                  </div>
                </Link>
                <div className='dropdown-divider' />
                <Link
                  to='/all-notifications'
                  className='dropdown-item text-center text-secondary'
                >
                  All Notifications
                </Link>
              </div>
            </div>
          </li>
          <li className='nav-item auth_menu'>
            <div className='dropdown'>
              <Link
                className='btn shadow-none'
                to='#'
                id='auth-user-dropdown'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
                onClick={auth_dropdown_handler}
              >
                Hello {name} <i className='fa fa-caret-down' />
              </Link>

              <div
                className={`dropdown-menu user-shortcut-controls ${authPopup ? 'show' : null
                  }`}
                aria-labelledby='auth-user-dropdown'
              >
                <Link className='dropdown-item' to='#' onClick={handleLogout}>
                  Logout
                </Link>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </>
  )
}

Navbar.propTypes = {
  dashboard: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getProfile: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  auth: state.auth
})

export default connect(mapStateToProps, { getProfile, logoutUser })(
  withRouter(Navbar)
)
