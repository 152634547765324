import React from 'react'
import moment from 'moment'
import { api_url } from '../../../utils/api'

const BasicDetails = (props) => {
  const { details } = props
  const basicInfo = details
  const image =
    basicInfo && basicInfo.photoId ? basicInfo.photoId : api_url + '/default.png'

  return (
    <>
      <img src={image} alt='profile-img' className='details-image' />
      <h1>
        {basicInfo ? basicInfo.fullName : ''}{' '}
      </h1>
      <p>{basicInfo ? moment(basicInfo.created_at).format('MMM DD, YYYY') : ''}</p>
    </>
  )
}

export default BasicDetails
