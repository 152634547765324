import React, { memo, useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { api_url } from '../../../utils/api'
import BasicDetails from './BasicDetails'

import Form from './Form'
import BookingHistory from './BookingHistory'
import { validateEditWalker } from '../../../validations/add-walker'
import { getWalker, updateWalker } from '../../../actions/dashboardActions'

const WalkerDetails = (props) => {
  const { id } = useParams()
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [insuranceProof, setInsuranceProof] = useState('')
  const [photoId, setPhotoId] = useState('')
  const token = localStorage.getItem('jwtToken')

  console.log('props', props.location.state.el)

  useEffect(() => {
    if (props.location.state.el.basicInfo) {
      const data = props.location.state

      const basicInfo = data && data.el.basicInfo
      const walkerInsuranceProof =
        basicInfo && basicInfo.insuranceProof
          ? basicInfo.insuranceProof
          : api_url + '/default.png'
      setInsuranceProof(walkerInsuranceProof)

      const walkerPhotoId =
        basicInfo && basicInfo.photoId
          ? basicInfo.photoId
          : api_url + '/default.png'
      setPhotoId(walkerPhotoId)
      const {
        image,
        fullName,
        email,
        phoneNumber
      } = props.location.state.el.basicInfo
      setFullName(fullName || '')
      setEmail(email || '')
      setPhoneNumber(phoneNumber || '')
    }
  }, [props])

  const handleDetailsUpdate = (e) => {
    e.preventDefault()

    const result = validateEditWalker(fullName, email, phoneNumber)
    if (result) return toast.error(result)

    const walker = {
      id,
      fullName,
      email,
      phoneNumber
    }

    props.updateWalker(walker, token)
    props.getWalker(id, token)
  }

  // const imageModalHandler = (image) => {
  //   console.log("imagess--", image);
  //   setImageModal(insuranceProof);
  // };

  const toDataURL = (url) => {
    return fetch(url).then((response) => {
      return response.blob()
    }).then(blob => {
      return URL.createObjectURL(blob)
    })
  }
  const download = async (file, filename) => {
    const link = document.createElement('a')
    if(file.includes('api.gowalkies.app')){
      link.href = await toDataURL(file)
    }else{
      link.href = file
    }
    
    link.download = filename + '.jpg'
    document.body.appendChild(link)

    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      {/* {<ImageModal imgSrc={about} />} */}
      <div className='doctor-details'>
        <h3 className='text-left'>Walker Details</h3>
        <div className='row mx-0'>
          <div className='col-md-4 pl-0'>
            <div className='basic-details'>
              <BasicDetails data={props.location.state.el} />
            </div>
          </div>
          <div className='col-md-8 pr-0'>
            <div className='personal-details'>
              <h5 className='text-left'>Personal Details</h5>
              <Form
                fullName={fullName}
                setFullName={setFullName}
                email={email}
                setEmail={setEmail}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                handleDetailsUpdate={handleDetailsUpdate}
                accessRole={props.accessRole}
              />
            </div>
          </div>
        </div>

        <div className='row mx-0 proof_row'>
          <div className='col-sm-6 pl-0'>
            <h4 className='proof_heading'>Insurance Proof</h4>
            <div className='basic-details'>

                   <div
              style={{cursor:'pointer'}}
                onClick={() => download(insuranceProof, 'InsuranceProof')}
              >
                <img
                  src={insuranceProof}
                  alt='profile-img'
                  className='proof_img'
                />
                <i className='fa fa-download' />
              </div>
            </div>
          </div>
          <div className='col-sm-6 pr-0'>
            <h4 className='proof_heading'>Photo ID</h4>
            <div className='basic-details'>
                         <div
              style={{cursor:'pointer'}}
                onClick={() => download(photoId, 'PhotoId')}
              >

                <img src={photoId} alt='profile-img' className='proof_img' />
                <i className='fa fa-download' />
              </div>

            </div>
          </div>
        </div>

        <div className='row mx-0'>
          <div className='col-md-12 px-0'>
            <div className='appointment-history'>
              <h5 className='text-left'>Booking History</h5>
              <div className='table-responsive'>
                {' '}
                <BookingHistory
                  data={props.dashboard.walkerBookings}
                  walkerInfo={props.dashboard.walkerInfo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

WalkerDetails.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getWalker: PropTypes.func.isRequired,
  updateWalker: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  accessRole:state.dashboard.accessRole
})

export default connect(mapStateToProps, { getWalker, updateWalker })(
  memo(WalkerDetails)
)
