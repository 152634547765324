import React from 'react'
import moment from 'moment'
import { api_url } from '../../../utils/api'

const BasicDetails = (props) => {
  const { details } = props
  const basicInfo = details && details.basicInfo
  const image =
    basicInfo && basicInfo.image ? basicInfo.image : api_url + '/default.png'

  return (
    <>
      <img src={image} alt='profile-img' className='details-image' />
      <h1>
        {basicInfo ? basicInfo.fullName : ''}{' '}
      </h1>
      <h6>
        {basicInfo ? basicInfo.email : ''}{' '}
        <sup
          className={
            basicInfo
              ? basicInfo.isVerified
                ? 'verified'
                : 'unverified'
              : ''
          }
        >
          {basicInfo
            ? basicInfo.isVerified
              ? 'verified'
              : 'unverified'
            : ''}
        </sup>
      </h6>

      <p>{details ? moment(details.created_at).format('MMM DD, YYYY') : ''}</p>
    </>
  )
}

export default BasicDetails
