import React from 'react'
import ButtonLoader from '../Loader/ButtonLoader'

const Button = ({ loading, value, className, type, handleClick }) => {
  return (
    <button className={className} type={type} onClick={handleClick}>
      {loading
        ? <ButtonLoader />
        : value}
    </button>
  )
}

export default Button
