import React, { useEffect } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Modal, ListGroup } from 'react-bootstrap'
// import ReactAudioPlayer from "react-audio-player";

import { api_url } from '../../../utils/api'
import './styles.scss'

const ViewBookingDetails = (props) => {
  const owner_details =
    (props.details.ownerId && props.details.ownerId.basicInfo) || {}

  const walker_details =
    (props.details.walkerId && props.details.walkerId.basicInfo) || {}

  const details = (props.details && props.details) || {}

useEffect(()=>{
},[])
  return (
    <>
      <Modal
        show={props.showViewModal}
        onHide={() => props.setShowViewModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>BookingHistory Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup variant='flush'>
            {props.details && (
              <>
              { Object.keys(owner_details).length !== 0 ?
              <>
                <h6>Owner Details</h6>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Name{' '}
                  <span>
                    {owner_details && owner_details.fullName ? owner_details.fullName : '-'}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Email <span>{owner_details && owner_details.email ? owner_details.email : '-'}</span>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Phone Number{' '}
                  <span>{owner_details && owner_details.phoneNumber ? owner_details.phoneNumber : '-'}</span>
                </ListGroup.Item>
                </>
                :null}
                 { Object.keys(walker_details).length !== 0 ?
                <>
                <h6>Walker Details</h6>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Name{' '}
                  <span>
                    {walker_details && walker_details.fullName ? walker_details.fullName : '-'}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Email <span>{walker_details && walker_details.email ? walker_details.email : '-'}</span>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Phone Number{' '}
                  <span>{walker_details && walker_details.phoneNumber ? walker_details.phoneNumber : '-'}</span>
                </ListGroup.Item>
                </>
                  :null}

                <h6>Booking Details</h6>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  BookingID
                  <span>{details && details.bookingId}</span>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Booking Type
                  <span>{details && details.requestType}</span>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Date
                  <span>{details &&  details.date}</span>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Pickup Location
                  <span>{details && details.pickUpLocation}</span>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Drop Location
                  <span>{details && details.dropLocation}</span>
                </ListGroup.Item>

                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Time Slot
                  <span>{details && details.startTime + '-' + details.endTime}</span>
                </ListGroup.Item>
                {details.requestType ==='custom' ?
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                 Services
                  <span style={{width:'60%',textAlign:'right'}}>{details && details.requestedService}</span>
                </ListGroup.Item>:null}
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Amount (with commission)
                  <span>{details && details.paymentAmount}</span>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Amount (without commission)
                  <span>{details && details.paidAmount ? details.paidAmount : '-'}</span>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Status{' '}
                  <span className={details.status}>{details.status}</span>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                  Created On{' '}
                  <span>
                    {moment(details.created_at).format('MMM DD, YYYY')}
                  </span>
                </ListGroup.Item>
              </>
            )}
          </ListGroup>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ViewBookingDetails
